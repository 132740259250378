@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  background-color: #1f1f1f;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.logo {
  max-width: 150px; /* Adjust this value to your needs */
  width: 100%; /* This makes the image responsive */
  height: auto; /* This maintains the aspect ratio of the image */
}

.container {
  width: 80%;
  margin: 0 auto;
}

/* Styles for tablet screens */
@media screen and (max-width: 768px) {
  .container {
    width: 90%;
  }
}

/* Styles for mobile screens */
@media screen and (max-width: 480px) {
  body {
    font-size: 14px;
  }

  .container {
    width: 95%;
  }
}

.progress-bar {
  background-color: rgba(255, 255, 255, 0.5); /* white with 50% opacity */
}